import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import Header from "../../components/Header/index.js"
import SEO from "../../components/seo"
import { withPreview } from "gatsby-source-prismic"

const ClientServices = ({ data }) => {
  const section = data.prismicClientServicesPage.data
  const heroImage = section.hero_banner_image.fluid.src

  return (
    <Layout>
      <SEO title={section.page_title} />
      <Header image={heroImage} title={section.hero_title.text} />
      <section key={"services"} className={`uk-section`}>
        <div className="uk-container">
          <div>
            {section.services.map((item, i) => (
              <div
                key={`service=${i}`}
                className="uk-grid uk-grid-item-match uk-flex-top"
                data-uk-grid
              >
                <div className="uk-width-auto@m">
                  <div
                    style={{ height: "48px", width: "48px", background: "#4D13F2" }}
                    className="uk-background-primary-light uk-border-circle uk-flex uk-flex-middle uk-flex-center uk-padding-small uk-margin-auto-left uk-margin-auto-right"
                    dangerouslySetInnerHTML={{ __html: item.svg_icon }}
                  />
                </div>
                <div className="uk-width-expand">
                  <div dangerouslySetInnerHTML={{ __html: item.description.html }} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default withPreview(ClientServices)

export const userGuidesQuery = graphql`
  {
    prismicClientServicesPage {
      uid
      data {
        page_title
        hero_title {
          html
          text
        }
        hero_banner_image {
          fluid(maxWidth: 1400) {
            ...GatsbyPrismicImageFluid_noBase64
          }
        }
        services {
          svg_icon
          description {
            html
          }
        }
      }
    }
  }
`
